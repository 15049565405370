import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SelectInput,
  useDataProvider,
} from "react-admin";
import { Box, Chip, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectedChip: {
    margin: "4px",
    backgroundColor: "#e3f2fd",
    "& .MuiChip-label": {
      color: "#1976d2",
    },
    "& .MuiChip-icon": {
      color: "#1976d2",
    },
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
    margin: "8px 0",
  },
}));

const NotificationCreate = (props) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState(null);

  // Actions available for notifications
  const actionChoices = [
    { id: "open-profile", name: "Open Profile" },
    { id: "open-url", name: "Open URL" },
  ];

  const transform = (data) => {
    const { action, idolId, url, ...rest } = data;

    return {
      ...rest,
      userIds: selectedUsers.map((user) => user.id),
      ...(action && {
        data: {
          action,
          ...(idolId && selectedUsername && { username: selectedUsername }),
          ...(url && { url }),
        },
      }),
    };
  };

  const handleUserSelect = (value, user) => {
    if (user && !selectedUsers.find((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  return (
    <Create {...props} title="Create Notification" transform={transform}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput multiline source="text" rows={10} />

        <RadioButtonGroupInput
          source="group"
          choices={[
            { id: "userIds", name: "Select User" },
            { id: "all", name: "All" },
            { id: "endUsers", name: "Users" },
            { id: "idols", name: "Idols" },
          ]}
        />

        <FormDataConsumer>
          {({ formData }) =>
            formData.group === "userIds" ? (
              <>
                <ReferenceInput source="tempUserId" reference="Client">
                  <AutocompleteInput
                    optionText={(record) =>
                      `${record.firstName || "-"} ${record.lastName || "-"} | ${
                        record.email || record.callingCode + record.mobileNumber
                      }`
                    }
                    onChange={handleUserSelect}
                    fullWidth
                    label="Select Users"
                    helperText="Search and select users to send notification"
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                  />
                </ReferenceInput>

                {selectedUsers.length > 0 && (
                  <Box className={classes.chipsContainer}>
                    {selectedUsers.map((user) => (
                      <Chip
                        key={user.id}
                        icon={<GroupIcon />}
                        label={`${user.firstName} ${user.lastName}`}
                        onDelete={() => handleRemoveUser(user.id)}
                        deleteIcon={
                          <IconButton size="small">
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        }
                        className={classes.selectedChip}
                      />
                    ))}
                  </Box>
                )}
              </>
            ) : null
          }
        </FormDataConsumer>

        {/* Action Selection */}
        <SelectInput
          source="action"
          choices={actionChoices}
          label="Notification Action (Optional)"
        />

        {/* Conditional Fields based on Action */}
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.action === "open-profile") {
              return (
                <>
                  <ReferenceInput
                    label="Select Idol"
                    source="idolId"
                    reference="Idol"
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        `${record.firstName} ${record.lastName} (${record.username})`
                      }
                      onChange={(value, idol) => {
                        if (idol) {
                          setSelectedUsername(idol.username);
                        }
                      }}
                      fullWidth
                      label="Select Idol Profile"
                      helperText="Search and select an idol whose profile will be opened"
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                    />
                  </ReferenceInput>

                  {selectedUsername && (
                    <Box sx={{ mt: 1 }}>
                      <Chip
                        icon={<PersonIcon />}
                        label={`Selected Username: ${selectedUsername}`}
                        onDelete={() => setSelectedUsername(null)}
                        deleteIcon={
                          <IconButton size="small">
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        }
                        className={classes.selectedChip}
                      />
                    </Box>
                  )}
                </>
              );
            }

            if (formData.action === "open-url") {
              return (
                <TextInput
                  source="url"
                  fullWidth
                  label="URL"
                  helperText="Enter the URL that will be opened in WebView"
                  validate={(value) => {
                    if (value && !/^https?:\/\/.+/.test(value)) {
                      return "Please enter a valid URL starting with http:// or https://";
                    }
                  }}
                />
              );
            }

            return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default NotificationCreate;
